const movieSections = document.querySelectorAll('.section-video');

movieSections.forEach((section) => {
    const movie = section.querySelector('.js-movie');
    const poster = section.querySelector('.section-video__poster');
    movie.pause();
    // intersect

    function handleIntersection(entries, observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                movie.muted = false;
                if(!movie.parentElement.classList.contains('paused')) {
                    movie.click();
                    movie.parentElement.classList.add('paused');
                }
            } else {
                if(!movie.parentElement.classList.contains('active')) {
                    movie.pause();
                }
            }
        });
    }
    
    const observer = new IntersectionObserver(handleIntersection, {
        root: null, 
        rootMargin: '0px', 
        threshold: 0.7, 
    });

    observer.observe(section); 


    // Pause on click

    movie.addEventListener('click', (e) => {
        if (movie.parentElement.classList.contains("paused")) {
            movie.parentElement.classList.remove('paused');
            movie.classList.add('active');
            movie.classList.remove('-hide');
            movie.play();

            if (poster) {
                poster.classList.add("-hide");

                setTimeout(() => {
                    poster.remove()
                }, 500);
            }

        } else {
            movie.parentElement.classList.add('paused');
            movie.pause();
        }
    })
});